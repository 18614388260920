.ui.menu {
  border-radius: 0;
  margin-bottom: 0;
}

.ui.inverted.menu .header.item {
  color: #8bc34a;
  font-size: 1.2em;
}

.menu-selected {
  color: #8bc34a;
}

.page-container-segment {
  width: 92%;
}

.page-container {
  height: 95vh;
  overflow-y: scroll;
}

/* Router animations */
.container {
  position: relative;
}

/* .page {
  position: absolute;
  left: 15px;
  right: 15px;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
} */

.fade-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 250ms ease-out, transform 300ms ease;
}
.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);

  transition: opacity 250ms ease-out, transform 300ms ease;
}

/* End */

/* Accordion animations */
.ui.accordion:not(.styled) .title~.content:not(.ui) {
  /* padding: .5em 0 1em; */
  padding: 0;
}
.ui.accordion .title~.content {
  /* display: none; */
  display: block;
}
.expand {
  transition: .4s max-height ease-out, .4s opacity ease-out;
  max-height: 0;
  opacity: 0;
  overflow: hidden; 
}
.active.expand {
  max-height: 1000px;
  opacity: 1;
}
